import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';

import variables from '../styles/variables';
import Layout from '../components/layout';
import { PageTitle, SectionTitle } from '../components/titles';
import Tag from '../components/tag';

const ProjectsList = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
`;

const StyledLink = styled(Link)`
  display: block;
  color: ${props => props.theme.colorText};
`;

const ProjectsPost = styled.li`
  display: block;
  position: relative;
  margin-bottom: 2rem;

  @media only screen and (min-width: calc(${variables.screenWidth} + 1px)) {
    margin-right: 2rem;
  }
`;

const CoverImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem 2rem;
  background: linear-gradient(to bottom, #000000bb, #00000000);
  transition: background 0.3s, border 0.3s;

  @media only screen and (min-width: calc(${variables.screenWidth} + 1px)) {
    &:hover {
      background: linear-gradient(
        to bottom,
        ${props => props.theme.colorPrimaryLight},
        #00000000
      );
      border: 2px solid ${props => props.theme.colorPrimaryLight};
    }
  }
`;

const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProjectsPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            tags
            slug
            coverImage {
              title
              fluid(quality: 100, maxWidth: 512) {
                src
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <PageTitle>Projects</PageTitle>
      <ProjectsList>
        {data.allContentfulProjectsPost.edges.map(edge => (
          <StyledLink key={edge.node.id} to={`/projects/${edge.node.slug}`}>
            <ProjectsPost>
              <CoverImage
                src={edge.node.coverImage.fluid.src}
                alt={edge.node.coverImage.title}
              />
              <OverlayContainer>
                <SectionTitle>{edge.node.title}</SectionTitle>
                {edge.node.tags.map(tag => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </OverlayContainer>
            </ProjectsPost>
          </StyledLink>
        ))}
      </ProjectsList>
    </>
  );
};

ProjectsPage.Layout = Layout;

export default ProjectsPage;
